import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ameluxe-frontend';
  isLanding: boolean = true;
  pageLoaded: boolean = false;
  constructor(
    public activeRoute: ActivatedRoute,
    private router: Router
  ) {

  }
  @HostListener('window:scroll', ['$event'])
  scrollHandler($event) {
      console.log("scrolling...");
  }
  ngOnInit() {
    this.router.events.forEach((event) => {
      window.scrollTo(0,0);
    });
    this.router.events.subscribe((url:any) => {
      if ( url instanceof NavigationEnd){
        if (url.url == '/' || url.url == '/home/landing') {
          this.isLanding = true;
        } else {
          this.isLanding = false;
        }
        this.pageLoaded = false;
        setTimeout(() => {
          this.pageLoaded = true;
        }, 1000);        
      }
    });
  }

}
