import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/api.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  public navmenu = [];
  author = {};
  authorId = 100;
  public innerWidth: any;
  sub_email: string = '';

  constructor(
    private apiService: ApiService,
    private router: Router
  ) { }

  ngOnInit() {
    this.innerWidth = window.innerWidth;
    // Navmenu Items
    this.apiService.getNavMenu({ navbar: 'footer' })
      .subscribe(reslatest => {
        if (reslatest.code === 0) {
          if (reslatest.result.length > 0) {
            this.navmenu = reslatest.result;
          } else {

          }

        } else {

        }
      });

    // Author details
    this.apiService.getUserDetails({ id: this.authorId })
      .subscribe(resp => {
        if (resp.code === 0) {
          if (resp.result.length > 0) {
            this.author = resp.result[0];
          } else {

          }

        } else {

        }
      });
  }

  goToArticle(id) {
    this.router.navigate(['article/single/', id]);

  }

  goToContact() {
    window.open("./contact-us")

  }

  goToContactFragment() {
    window.open("./contact-us#talk-briefly")
  }

  goToRoute(r) {
    this.router.navigate([r]);
  }
  goToRouteFragment(r, f) {
    this.router.navigate([r], { fragment: f });
  }
  subscribeStart() {

    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (this.sub_email && re.test(String(this.sub_email).toLowerCase())) {
      this.apiService.commoncreate({ table: 'email_subscriber', data: { email: this.sub_email } })
        .subscribe(resp => {
          if (resp.code === 0) {
            var content = 'A new user has requested for email subscription<br>Email: ' + this.sub_email;
            this.apiService.sendCustomMail({ to_email: 'ameluxetravel@gmail.com', subject: 'New Email Subscription from Ameluxe', content: content })
              .subscribe(resp => {
                if (resp.code === 0) {

                }
              });
            Swal.fire('Thank You for joining the Ameluxe tribe');
            this.sub_email = '';
          } else {

          }
        });
    } else {
      Swal.fire('Please enter a valid email');
    }
  }
  goHome() {
    this.router.navigate(['home']);
  }
}
