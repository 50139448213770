import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpHeaders,
  HttpEventType,
  HttpEvent,
} from "@angular/common/http";
import { map } from "rxjs/operators";
import { environment } from "../environments/environment";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  public baseUrl = environment.apiUrl;
  constructor(private _http: HttpClient, public router: Router) {}

  getArticle(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "get_article_by_id.php",
      data
    );
  }

  viewCapture(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "view_entity_capture.php",
      data
    );
  }

  getGuestToken() {
    return localStorage.getItem("ameluxe_guest_token");
  }

  getRand() {
    var dat_e = new Date();
    var uniqu_e = (Math.random() * 1000 + "").slice(-4);

    var d_str = dat_e
      .toISOString()
      .replace(/[^0-9]/g, "")
      .replace(dat_e.getFullYear().toString(), uniqu_e);
    return d_str;
  }

  getRecommendedArticles(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "recommended_articles.php",
      data
    );
  }

  getFeaturedArticle(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "get_featured_articles.php",
      data
    );
  }

  getCategory(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "get_all_categories.php",
      data
    );
  }

  getTestimonial(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "select_testimonial.php",
      data
    );
  }

  getLatestArticle(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "get_latest_articles.php",
      data
    );
  }

  getMoodFor(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "get_mood_for_articles.php",
      data
    );
  }

  getIfancy(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "ifancy_categories.php",
      data
    );
  }

  getUserDetails(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "select_user_details.php",
      data
    );
  }

  getWallOfStories(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "get_wall_of_stories.php",
      data
    );
  }

  getAllRegions(data) {
    return this._http.post<ResApi>(this.baseUrl + "get_all_regions.php", data);
  }

  commoncreate(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "insert_email_subscriber.php",
      data
    );
  }
  insertUserLikes(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "insert_user_likes.php",
      data
    );
  }
  getNavMenu(data) {
    return this._http.post<ResApi>(this.baseUrl + "select_navmenu.php", data);
  }
  commoncreateguest(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "common_create_guest.php",
      data
    );
  }
  sendCustomMail(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "update_subscribe_email.php",
      data
    );
  }
  bookHoliday(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "common_create_with_table_name_without_auth.php",
      data
    );
  }
  fetchHoliday(data) {
    return this._http.post<ResApi>(
      this.baseUrl + "common_select_with_table_name.php",
      data
    );
  }
}

/* Result interface */
interface ResApi {
  code: any;
  result: any;
  msg: any;
}
