import { Component, OnInit } from "@angular/core";
import Swal from "sweetalert2";
import { ApiService } from "src/app/api.service";
import { Router, NavigationEnd, RouterEvent } from "@angular/router";
import { filter } from "rxjs/operators";

@Component({
  selector: "app-topnav",
  templateUrl: "./topnav.component.html",
  styleUrls: ["./topnav.component.sass"],
})
export class TopnavComponent implements OnInit {
  public navmenu = [];
  public navmenufooter = [];
  author = {};
  authorId = 100; //Admin contributor
  public innerWidth: any;
  public meneOpen: boolean = false;
  public isSingleArticle: boolean = false;
  constructor(private apiService: ApiService, private router: Router) {}

  ngOnInit() {
    this.router.events.subscribe((url: any) => {
      if (url instanceof NavigationEnd) {
        console.log("url", url.url);
        if (url.url.startsWith("/article/single")) {
          this.isSingleArticle = true;
        } else {
          this.isSingleArticle = false;
        }
      }
    });
    const href = this.router.url;
    if (href.startsWith("/article/single")) {
      this.isSingleArticle = true;
    } else {
      this.isSingleArticle = false;
    }
    this.innerWidth = window.innerWidth;
    // Navmenu Items
    this.apiService.getNavMenu({ navbar: "header" }).subscribe((reslatest) => {
      if (reslatest.code === 0) {
        if (reslatest.result.length > 0) {
          this.navmenu = reslatest.result;
          console.log("nav", this.navmenu);
        } else {
        }
      } else {
      }
    });

    // Navmenu Items
    this.apiService.getNavMenu({ navbar: "footer" }).subscribe((reslatest) => {
      if (reslatest.code === 0) {
        if (reslatest.result.length > 0) {
          this.navmenufooter = reslatest.result;
        } else {
        }
      } else {
      }
    });

    // Author details
    this.apiService.getUserDetails({ id: this.authorId }).subscribe((resp) => {
      if (resp.code === 0) {
        if (resp.result.length > 0) {
          this.author = resp.result[0];
        } else {
        }
      } else {
      }
    });
  }
  subscribeStart() {
    Swal.fire({
      title: "Add beauty <br/> to your inbox.",
      html: '<div class="alert-div">We will send you travel stories and postcard picture that you would love to have.</div>',
      input: "text",
      inputPlaceholder: "Email",
      showCancelButton: true,
      cancelButtonText:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.22 23.3"><title>close-thin</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Desktop"><g id="PopUp-Example"><g id="EMAIL-POP-UP"><g id="Close"><path id="Line" d="M22.16,1.14,1.06,22.23" fill="none" stroke="#000" stroke-linecap="square" stroke-width="1.5"></path><path id="Line-Copy" d="M22.16,22.16,1.06,1.06" fill="none" stroke="#000" stroke-linecap="square" stroke-width="1.5"></path></g></g></g></g></g></g></svg>',
      confirmButtonText:
        '&nbsp;<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.21 10.25"><title>arrow</title><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path id="Line-2-Copy-3" d="M.5,5.14H21.41" fill="none" stroke="#000" stroke-linecap="square"></path><path id="Rectangle" d="M17.67,9.9,22.5,5.07,17.78.35" fill="none" stroke="#000"></path></g></g></svg>',
    }).then((result) => {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (result.value && re.test(String(result.value).toLowerCase())) {
        this.apiService
          .commoncreate({
            table: "email_subscriber",
            data: { email: result.value },
          })
          .subscribe((resp) => {
            if (resp.code === 0) {
              Swal.fire("Thank You for joining the Ameluxe tribe");
            } else {
            }
          });
      } else {
        Swal.fire("Please enter a valid email");
      }
    });
  }

  goToCategory(id) {
    this.meneOpen = false;
    // this.router.navigate(['category/page/', id]);
    if (id === "8") {
      window.open("about-ameluxe", "_target");
    } else {
      window.open("category/page/" + id, "_self");
    }
  }

  goToBookHoliday() {
    window.open("book-holiday", "_self");
  }

  goHome() {
    this.meneOpen = false;
    this.router.navigate(["home"]);
  }

  toggleMenu() {
    this.meneOpen = !this.meneOpen;
    console.log("menuopen", this.meneOpen);
  }
  goToRoute(r) {
    this.meneOpen = false;
    this.router.navigate([r]);
  }
  goToRouteFragment(r, f) {
    this.meneOpen = false;
    this.router.navigate([r], { fragment: f });
  }
}
